/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Bio = () => {
  const { author } = useStaticQuery(graphql`
    query BioQuery {
      # if there was more than one user, this would need to be filtered
      author: wpUser {
        firstName
        twitter: name
        description
        avatar {
          url
        }
      }
    }
  `)

  const avatarUrl = author?.avatar?.url

  return (
    <div className="bio">
      {/* {avatarUrl && (
        <img
          alt={author?.firstName || ``}
          className="bio-avatar"
          src={avatarUrl}
        />
      )} */}
      {author?.firstName && (
        <p>
          {/* Written by <strong>{author.firstName}</strong> */}
          {author?.description || null}
          my unused devlog designing and building weird websites
        </p>
      )}
      <div class="buttons-bio">
        <ul>
          <li class="link-bio">
            <Link to="https://jeeyoonhyun.com/">portfolio</Link>
          </li>
          <li class="link-bio">
            <Link to="https://twitter.com/jeeyoon_hyun">twitter</Link>
          </li>
          <li class="link-bio">
            <Link to="https://github.com/jeeyoonhyun">github</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Bio
