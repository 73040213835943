import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
const _ = require("lodash");

const tagLink = tag => {
  return (`/tags/${_.kebabCase(tag)}/`)
}

const TagIndex = ({
  data,
  pageContext: { tagName, tagCount },
}) => {
  const posts = data.allWpPost.nodes
  const tagHeader = `${tagCount} post${tagCount === 1 ? "" : "s"
    } tagged with "${tagName}"`

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title={tagHeader} />

      <Bio />
      <h2 class = "tag-archive-header">{tagHeader}</h2>
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title

          return (
            <li key={post.uri}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <div class="date">{post.date}</div>
                  <h1>
                    <Link to={post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h1>

                  <div class="tag-container">
                    {post.tags.nodes.length > 0 && post.tags.nodes.map(elem => (<span class="tag"><Link to={tagLink(elem.name)}>{elem.name}</Link></span>))}
                  </div>
                </header>
                {/* <section itemProp="description">{parse(post.excerpt)}</section> */}
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default TagIndex

export const pageQuery = graphql`
  query WordPressTagArchive($tagName:String) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: {tags: {nodes: {elemMatch: {name: {eq: $tagName}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
            count
          }
        }
      }
    }
  }
`
